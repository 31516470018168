





































































































































/* eslint-disable global-require */
import { Component, Vue } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import {
  BLink,
  BImg,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BCardText,
  BCardTitle,
  BCard,
  BAlert,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import VuexyLogo from "@/@core/layouts/components/Logo.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  confirmed,
  password,
  required,
} from "@/@core/utils/validations/validations";
import { UtilisateurValidatePasswordModel } from "@/api/models/utilisateurs/utilisateur";
@Component({
  components: {
    BForm,
    BAlert,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BImg,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BCardText,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
})
export default class ResetPassword extends Vue {
  required = required;
  password = password;
  confirmed = confirmed;
  message = "";
  variant = "";
  payload: UtilisateurValidatePasswordModel = {};
  loading = false;
  isReset = false;
  passwordFieldType = "password";
  confirmPasswordFieldType = "password";

  created() {
    this.payload = {
      userId: this.$route.params.userId,
      passwordToken: this.$route.params.passwordToken,
      password: "",
    };
  }

  get passwordToggleIcon() {
    return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  }

  get confirmPasswordToggleIcon() {
    return this.confirmPasswordFieldType === "password"
      ? "EyeIcon"
      : "EyeOffIcon";
  }

  async validate() {
    this.loading = true;
    await this.$http.myressif.utilisateurs.resetPassword(this.payload).then(
      (res: any) => {
        this.message = "Votre mot de passe à été réinitialisé avec succés !";
        this.variant = "success";
        this.loading = false;
        this.isReset = true;
      },
      (error: any) => {
        this.variant = "danger";
        this.message =
          "Il y'a eu un soucis avec la validation de votre mot de passe";
        this.loading = false;
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  togglePasswordVisibility() {
    if (this.passwordFieldType === "password") this.passwordFieldType = "text";
    else this.passwordFieldType = "password";
  }

  toggleConfirmPasswordVisibility() {
    if (this.confirmPasswordFieldType === "password")
      this.confirmPasswordFieldType = "text";
    else this.confirmPasswordFieldType = "password";
  }
}
